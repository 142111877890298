import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M13699 21548 c-9 -16 -12 -390 -15 -1447 -3 -965 -7 -1476 -15 -1581
-9 -111 -10 -276 -4 -590 5 -239 10 -1049 10 -1800 1 -751 5 -1384 9 -1407 l8
-43 69 0 c38 0 85 6 103 14 19 8 60 19 91 26 50 10 164 55 205 81 8 5 24 13
35 18 11 5 37 23 59 40 21 17 42 31 45 31 21 0 221 216 221 239 0 5 11 22 24
38 31 38 91 176 105 241 6 29 18 69 26 88 12 30 15 100 15 408 0 437 -3 422
82 392 29 -10 62 -27 73 -36 84 -71 334 -174 500 -205 44 -9 100 -22 125 -30
58 -18 342 -45 484 -45 147 0 432 35 526 65 41 13 95 27 120 30 25 4 59 15 75
25 17 10 47 21 67 24 58 10 142 64 171 108 22 34 138 118 164 118 16 1 134 86
238 173 172 144 235 204 292 275 12 15 34 43 50 62 58 73 90 116 103 139 7 14
21 34 31 44 10 11 25 35 34 53 9 18 22 37 29 41 7 4 19 26 26 48 7 22 18 47
25 55 7 8 18 33 25 55 7 22 20 51 30 65 9 14 22 45 29 70 7 25 22 65 33 89 11
24 24 72 29 105 5 34 18 88 30 121 19 54 22 88 26 320 6 271 -5 443 -31 493
-8 15 -17 50 -21 77 -9 63 -73 262 -95 295 -10 14 -21 36 -25 50 -9 29 -75
167 -107 225 -35 61 -192 272 -231 310 -61 59 -170 181 -193 215 -26 39 -101
107 -143 129 -13 7 -49 32 -80 56 -31 23 -77 53 -102 66 -168 86 -319 157
-349 164 -19 4 -54 16 -78 27 -24 10 -78 25 -120 33 -42 7 -97 21 -121 29 -24
9 -83 21 -130 26 -48 5 -111 14 -140 20 -30 5 -134 10 -232 10 -235 0 -414
-25 -669 -95 -78 -21 -263 -98 -340 -141 -14 -7 -44 -22 -66 -33 -23 -11 -45
-24 -48 -30 -4 -6 -21 -11 -37 -11 -21 0 -32 6 -39 23 -5 12 -14 166 -20 342
-11 341 -23 441 -64 544 -14 36 -26 71 -26 79 0 16 -64 126 -92 158 -10 11
-18 25 -18 32 0 6 -13 25 -30 42 -16 17 -30 34 -30 38 0 11 -136 122 -149 122
-6 0 -18 11 -27 24 -8 14 -28 28 -44 31 -16 4 -41 16 -57 28 -54 44 -312 126
-395 127 -38 0 -49 -4 -59 -22z m2411 -2293 c126 -17 253 -52 345 -96 33 -16
65 -29 71 -29 5 0 19 -8 30 -18 10 -9 31 -25 46 -35 118 -75 351 -318 373
-388 4 -13 16 -35 26 -48 10 -12 22 -39 25 -59 4 -20 18 -59 31 -88 30 -67 42
-165 42 -344 0 -181 -12 -281 -43 -345 -13 -27 -27 -64 -31 -82 -3 -18 -14
-42 -24 -55 -10 -13 -22 -37 -28 -53 -14 -40 -91 -150 -155 -220 -95 -105
-144 -148 -238 -207 -247 -156 -408 -196 -747 -185 -147 5 -182 9 -245 31 -40
13 -93 30 -118 36 -25 7 -56 20 -70 29 -14 10 -51 30 -83 46 -63 31 -220 157
-302 241 -27 29 -59 54 -70 58 -48 14 -126 141 -191 311 -26 67 -27 78 -31
325 -5 245 -4 260 20 380 21 101 36 144 77 225 48 94 128 195 155 195 13 0 45
25 142 110 83 72 128 101 253 161 220 105 468 140 740 104z"/>
<path d="M15580 18033 c0 -3 11 -26 25 -49 26 -44 83 -89 145 -115 52 -21 196
-18 240 5 58 30 114 81 138 125 l23 41 -285 0 c-157 0 -286 -3 -286 -7z"/>
<path d="M13122 17798 l3 -3033 100 0 100 0 3 3033 2 3032 -105 0 -105 0 2
-3032z"/>
<path d="M18063 17018 c-13 -17 -74 -250 -84 -318 -12 -91 -152 -405 -217
-489 -15 -20 -39 -54 -53 -76 -32 -49 -150 -165 -246 -242 -15 -13 -44 -36
-64 -53 -20 -16 -40 -30 -44 -30 -3 0 -27 -17 -53 -38 -73 -60 -180 -119 -257
-142 -38 -12 -83 -27 -100 -35 -79 -35 -156 -55 -300 -76 -229 -35 -347 -38
-449 -10 -39 11 -100 22 -136 26 -95 9 -261 68 -371 130 -52 30 -98 55 -102
55 -3 0 -32 11 -62 26 -32 14 -64 22 -75 19 -28 -9 -140 -301 -140 -366 0 -51
39 -93 112 -119 35 -13 106 -42 158 -66 99 -44 164 -65 410 -133 148 -41 183
-45 375 -42 131 2 338 19 430 36 170 31 201 39 220 52 11 7 29 13 42 13 12 0
25 5 28 10 3 6 13 10 21 10 22 0 289 128 354 170 36 23 60 37 101 59 20 11 49
31 65 44 38 32 132 97 140 97 3 0 29 26 57 58 28 31 88 95 132 142 45 47 96
109 114 138 18 28 37 52 41 52 5 0 25 30 46 68 20 37 51 87 69 112 31 43 150
329 174 415 6 22 29 106 51 187 50 181 48 191 -46 223 -33 12 -77 31 -98 43
-85 50 -221 78 -243 50z"/>
<path d="M18812 16767 c-6 -7 -19 -44 -30 -82 -25 -90 -186 -564 -210 -619
-11 -23 -33 -58 -49 -77 -17 -18 -46 -66 -66 -105 -20 -39 -46 -84 -59 -100
-14 -16 -39 -53 -56 -82 -18 -29 -61 -81 -95 -115 -35 -34 -80 -85 -100 -113
-20 -28 -62 -71 -92 -94 -30 -23 -55 -46 -55 -50 0 -5 -24 -26 -52 -48 -29
-22 -69 -54 -88 -71 -36 -33 -150 -111 -161 -111 -4 0 -50 -27 -102 -60 -53
-33 -101 -60 -107 -60 -7 0 -24 -6 -38 -14 -93 -48 -231 -106 -252 -106 -14 0
-50 -9 -80 -21 -197 -74 -427 -110 -725 -111 -280 -1 -403 16 -624 87 -205 67
-455 161 -525 199 -45 24 -51 25 -67 10 -21 -19 -105 -269 -114 -340 l-7 -50
54 -50 c49 -44 73 -56 229 -112 313 -112 425 -137 769 -173 118 -12 638 -7
660 6 9 6 109 24 165 30 42 4 111 17 140 26 17 4 66 19 110 33 44 13 96 27
115 31 19 4 55 18 80 30 25 12 83 35 130 51 48 15 111 46 145 69 33 23 85 50
115 60 83 26 137 55 183 98 43 40 122 97 135 97 38 1 467 412 522 501 19 31
66 96 105 144 64 81 95 131 221 365 25 47 54 101 64 120 63 114 210 576 210
659 0 18 -16 29 -87 62 -204 94 -286 117 -311 86z"/>
<path d="M10669 12707 c-66 -44 -84 -126 -43 -196 55 -92 191 -93 251 -2 33
49 32 119 -3 161 -54 64 -141 80 -205 37z"/>
<path d="M8470 12660 c-110 -11 -333 -38 -371 -46 -22 -4 -27 -12 -32 -47 -3
-24 -1 -44 5 -48 5 -4 50 -12 98 -19 48 -7 93 -16 99 -19 8 -5 10 -184 9 -631
l-3 -625 -99 -27 c-55 -15 -103 -31 -107 -35 -3 -5 -9 -25 -12 -46 l-6 -38
427 3 c404 4 431 5 507 26 212 59 337 169 381 336 59 221 -85 439 -315 475
-28 4 -54 10 -57 13 -3 3 24 22 60 42 149 84 226 257 181 411 -38 134 -130
211 -303 256 -90 24 -312 33 -462 19z m304 -116 c74 -18 114 -43 166 -101 55
-62 74 -130 68 -242 -5 -94 -20 -134 -72 -189 -54 -58 -69 -62 -251 -62 l-165
0 0 304 0 305 43 3 c65 5 146 -2 211 -18z m160 -724 c70 -27 131 -84 166 -155
21 -43 25 -64 25 -150 0 -110 -13 -148 -76 -219 -71 -82 -159 -105 -391 -106
l-138 0 0 331 0 331 178 -4 c154 -4 185 -8 236 -28z"/>
<path d="M12203 12656 c-67 -6 -124 -13 -126 -16 -2 -3 -7 -24 -10 -47 l-5
-42 99 -33 99 -32 -2 -635 -3 -634 -90 -20 c-49 -11 -92 -22 -94 -24 -2 -1 -7
-23 -11 -48 l-7 -45 302 0 302 0 7 38 c3 20 6 42 6 48 0 6 -28 17 -62 23 -35
7 -80 17 -100 22 l-38 10 0 712 c0 541 -3 716 -12 725 -14 14 -83 14 -255 -2z"/>
<path d="M10130 12200 c-77 -40 -167 -165 -200 -277 -7 -24 -19 -43 -27 -43
-16 0 -15 -8 -3 159 9 115 8 137 -4 149 -13 13 -36 12 -188 -7 -95 -13 -176
-26 -179 -29 -4 -4 -10 -25 -14 -48 l-6 -41 100 -29 101 -29 0 -395 0 -396
-94 -23 c-89 -23 -94 -25 -100 -54 -12 -61 -37 -57 342 -55 l347 3 0 50 0 49
-140 22 c-77 12 -141 23 -143 23 -2 1 -2 121 0 269 3 250 5 271 26 324 28 70
102 155 145 166 39 10 101 -2 138 -28 36 -26 40 -26 76 13 41 43 53 69 53 112
0 108 -125 171 -230 115z"/>
<path d="M11510 12207 c-83 -19 -145 -53 -194 -107 -89 -100 -118 -222 -77
-330 38 -101 101 -157 321 -285 157 -91 200 -134 200 -204 0 -67 -37 -105
-118 -121 -70 -13 -127 4 -178 55 -35 35 -85 122 -107 187 -5 14 -12 15 -44 6
-107 -27 -156 -146 -97 -234 34 -49 46 -59 119 -95 165 -80 399 -46 519 75 57
57 79 108 84 191 3 64 1 76 -30 135 -41 79 -97 126 -279 235 -194 115 -239
161 -239 245 0 91 54 150 138 150 91 0 147 -60 183 -196 l23 -86 35 6 c40 6
75 30 105 69 26 36 33 130 13 179 -40 96 -228 159 -377 125z"/>
<path d="M14982 12209 c-94 -16 -198 -66 -263 -126 -69 -65 -87 -108 -67 -168
17 -49 51 -74 114 -81 l51 -6 7 64 c11 107 32 166 69 188 75 46 199 4 244 -84
28 -54 43 -128 43 -217 l0 -87 -79 -11 c-304 -45 -481 -176 -498 -369 -8 -86
16 -151 74 -202 57 -50 106 -64 209 -57 110 6 175 38 252 123 33 35 62 64 64
64 3 0 8 -19 11 -42 6 -45 28 -81 71 -112 34 -26 155 -34 240 -16 54 11 56 12
58 46 1 19 2 41 2 51 1 12 -14 19 -61 29 -75 15 -108 36 -122 76 -7 19 -11
131 -11 287 0 410 -19 513 -107 591 -25 22 -62 44 -82 50 -58 15 -162 20 -219
9z m198 -747 l0 -158 -47 -36 c-154 -115 -313 -73 -313 83 0 82 51 153 144
201 39 21 177 66 204 67 9 1 12 -37 12 -157z"/>
<path d="M17895 12210 c-68 -11 -154 -44 -206 -82 -113 -79 -178 -167 -225
-303 -25 -72 -28 -94 -29 -215 0 -117 3 -145 24 -209 29 -89 103 -203 160
-249 157 -126 371 -146 563 -53 91 45 133 82 125 112 -14 49 -22 54 -58 31
-53 -32 -136 -52 -214 -52 -133 0 -242 79 -311 223 -50 107 -68 186 -68 307
-2 226 97 380 241 380 76 0 136 -48 189 -152 25 -50 26 -50 64 -45 137 18 157
182 31 261 -65 41 -188 61 -286 46z"/>
<path d="M18830 12205 c-80 -20 -127 -42 -197 -91 -78 -56 -123 -110 -168
-205 -85 -179 -86 -413 -4 -586 35 -73 123 -173 186 -210 107 -62 291 -83 414
-47 183 55 319 204 365 402 20 87 20 267 0 355 -21 88 -73 190 -130 253 -105
118 -296 170 -466 129z m194 -149 c77 -45 132 -136 176 -291 6 -22 14 -103 17
-180 7 -163 -6 -226 -62 -312 -49 -74 -109 -107 -194 -107 -171 1 -278 123
-325 373 -41 214 -3 399 101 491 57 50 90 60 175 56 50 -2 78 -10 112 -30z"/>
<path d="M12676 12188 c-16 -72 -17 -70 71 -98 46 -14 83 -27 83 -30 -1 -3
-75 -97 -165 -210 -91 -113 -165 -208 -165 -213 0 -9 160 -230 243 -335 85
-108 168 -187 230 -219 44 -23 65 -27 132 -27 44 0 97 6 118 13 31 11 37 17
37 41 0 16 2 35 6 43 4 11 -9 17 -54 26 -68 13 -130 50 -188 112 -50 53 -314
395 -314 406 1 5 67 89 148 188 l148 180 93 27 c51 14 94 28 96 30 1 1 6 22
10 46 l7 42 -266 0 -266 0 -4 -22z"/>
<path d="M13935 12193 c-77 -33 -132 -72 -183 -128 -28 -30 -53 -55 -57 -55
-3 0 -3 38 1 85 6 73 5 85 -10 91 -13 5 -366 -31 -372 -39 -1 -1 -4 -23 -8
-48 l-7 -45 96 -29 95 -28 -2 -625 -3 -626 -88 -22 c-49 -12 -90 -24 -91 -26
-2 -1 -6 -24 -9 -50 l-7 -48 295 0 294 0 6 44 c4 25 4 49 1 54 -3 5 -46 17
-96 27 l-90 19 0 593 0 592 53 40 c78 59 132 81 202 80 75 0 119 -21 162 -77
53 -70 75 -156 80 -317 6 -161 -4 -232 -46 -320 -56 -120 -107 -148 -261 -143
-58 2 -113 8 -123 12 -16 8 -18 3 -15 -65 l3 -74 55 -9 c82 -13 208 8 298 49
55 26 92 53 146 107 132 133 184 286 173 513 -12 237 -82 380 -222 453 -66 34
-207 42 -270 15z"/>
<path d="M20243 12191 c-61 -21 -147 -75 -209 -133 -25 -23 -47 -38 -50 -35
-4 3 -2 39 4 79 9 56 8 76 -1 85 -9 9 -53 7 -195 -12 -101 -14 -187 -27 -193
-30 -5 -4 -9 -25 -9 -49 l0 -43 98 -30 97 -30 0 -388 0 -389 -95 -25 -95 -26
-6 -43 -5 -42 293 0 293 0 0 40 c0 22 1 43 2 48 0 4 -34 15 -78 25 -43 10 -82
22 -86 25 -4 4 -8 167 -8 362 l-1 355 34 31 c47 43 134 74 208 74 97 0 160
-35 202 -115 21 -39 22 -51 22 -374 l0 -335 -79 -25 c-81 -27 -94 -38 -95 -88
l-1 -23 280 0 280 0 2 35 c0 19 1 40 2 47 1 7 -35 20 -89 33 l-90 20 -3 352
-2 351 47 42 c65 58 120 80 197 80 84 0 140 -30 181 -95 l30 -48 0 -340 0
-340 -75 -26 c-70 -24 -75 -28 -81 -61 -4 -19 -5 -38 -3 -42 3 -5 124 -8 268
-8 l264 0 7 46 c3 25 5 48 3 50 -3 2 -40 12 -84 22 l-79 18 -6 369 c-6 373 -9
405 -46 480 -43 84 -135 135 -247 135 -121 0 -219 -43 -323 -143 -34 -32 -66
-56 -71 -53 -5 3 -13 17 -16 32 -17 67 -87 135 -165 162 -51 18 -168 14 -228
-7z"/>
<path d="M22784 12186 c-68 -25 -103 -47 -187 -119 -32 -27 -61 -47 -64 -44
-3 4 -1 39 5 79 16 105 20 103 -179 78 -90 -12 -174 -25 -185 -30 -16 -6 -23
-19 -26 -49 l-3 -40 95 -34 95 -35 0 -388 0 -389 -90 -24 c-49 -13 -92 -25
-94 -27 -2 -2 -7 -21 -11 -44 l-7 -40 293 0 293 0 7 45 c3 25 5 47 3 49 -2 1
-42 11 -89 22 l-85 20 -3 359 c-3 410 -9 378 91 429 160 80 330 32 367 -104 6
-24 9 -160 8 -361 l-3 -323 -80 -25 c-44 -13 -82 -25 -84 -27 -2 -2 -7 -21
-11 -44 l-7 -40 282 0 282 0 7 41 c4 23 4 44 1 48 -4 3 -43 15 -88 27 l-82 20
-5 375 c-6 403 -9 424 -65 507 -27 40 -87 85 -134 101 -57 19 -178 13 -247
-13z"/>
<path d="M23888 12196 c-109 -29 -223 -126 -274 -234 -23 -47 -28 -72 -28
-137 -1 -71 3 -88 31 -146 31 -62 107 -146 158 -175 18 -10 16 -13 -24 -34
-24 -12 -73 -50 -107 -84 -59 -57 -64 -65 -64 -104 0 -58 32 -116 81 -149 39
-26 40 -28 23 -41 -11 -7 -45 -31 -76 -53 -76 -52 -111 -111 -110 -184 1 -165
163 -265 432 -266 196 -1 358 59 479 179 87 85 113 135 119 230 6 87 -12 143
-61 190 -57 55 -99 64 -342 72 -230 8 -302 19 -331 51 -25 28 -21 73 11 119
l28 41 71 -7 c50 -4 92 -1 140 10 251 59 385 261 316 476 -12 39 -20 74 -16
77 3 4 37 -3 74 -15 38 -12 76 -22 84 -22 19 0 62 118 52 143 -7 19 -19 20
-174 8 -100 -8 -107 -7 -155 17 -102 51 -233 66 -337 38z m158 -95 c39 -24 76
-72 106 -137 19 -41 23 -66 23 -154 0 -90 -3 -112 -23 -152 -30 -60 -71 -90
-133 -96 -66 -6 -93 2 -140 45 -64 58 -92 122 -97 224 -7 140 34 241 113 275
44 20 115 17 151 -5z m202 -1045 c51 -22 72 -57 72 -119 0 -148 -123 -227
-334 -214 -115 7 -175 28 -220 77 -63 70 -69 197 -13 258 22 24 22 24 237 20
179 -3 222 -7 258 -22z"/>
<path d="M10655 12183 c-93 -7 -170 -15 -172 -17 -2 -1 -6 -23 -9 -48 l-6 -45
96 -34 96 -33 0 -393 0 -392 -37 -10 c-21 -6 -63 -15 -93 -22 -30 -6 -58 -14
-61 -18 -4 -3 -9 -25 -13 -48 l-6 -43 305 0 c292 0 305 1 305 19 0 10 3 32 6
48 l6 30 -99 19 -98 20 -3 474 c-2 423 -4 476 -19 492 -9 10 -19 17 -22 17 -3
-1 -82 -8 -176 -16z"/>
<path d="M15646 12158 c-16 -70 -16 -69 61 -92 40 -12 76 -26 81 -31 5 -6 89
-225 187 -487 l178 -476 -37 -79 c-59 -124 -110 -166 -203 -165 -59 0 -112 15
-161 44 -31 19 -34 19 -52 3 -30 -27 -50 -79 -50 -129 0 -37 6 -52 31 -81 55
-62 115 -80 218 -64 149 23 239 114 339 344 40 93 427 1044 436 1072 4 14 28
30 70 48 55 23 65 32 70 57 12 61 24 58 -209 58 l-214 0 -7 -34 c-3 -19 -4
-40 -2 -47 2 -7 38 -22 79 -34 56 -17 73 -26 71 -39 -2 -9 -62 -163 -134 -342
-89 -221 -134 -321 -139 -311 -14 23 -249 651 -249 663 0 6 35 21 78 35 l77
24 3 43 3 42 -260 0 -261 0 -4 -22z"/>
<path d="M17015 11311 c-56 -34 -70 -58 -70 -120 0 -47 5 -64 27 -93 69 -91
213 -70 253 37 28 73 2 145 -65 180 -40 21 -109 19 -145 -4z"/>
<path d="M21742 11310 c-50 -31 -74 -76 -69 -132 8 -89 80 -147 168 -135 108
14 164 147 98 231 -45 57 -135 74 -197 36z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
